import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {FaSave} from "react-icons/fa";
import MuiBorderLabel from "../../ui/MuiBorderLabel";
import MuiInputTextField from "../../ui/MuiInputData/MuiInputTextField";
import UndoButton from "../../ui/Buttons/UndoButton";
import EdiliziaAppButton from "../../ui/Buttons/EdiliziaAppButton";
import FieldTypeSelect from "../../utils/field_types/FieldTypeSelect";
import {IFieldType} from "../../utils/field_types/IFieldType";
import field_types from "../../utils/field_types/field_types";
import {useApp} from "../../utils/global-contexts/AppContext";
import {IUserField} from "../../utils/interfaces/Estimate/IUserField";
import useApiCreateEstimateUserField from "./api-hooks/useApiCreateEstimateUserField";
import {useLocation} from "react-router-dom";
import useApiCreateFinalBalanceUserField from "./api-hooks/useApiCreateFinalBalanceUserField";
import useApiUpdateEstimateUserField from "./api-hooks/useApiUpdateEstimateUserField";
import useApiUpdateFinalBalanceUserField from "./api-hooks/useApiUpdateFinalBalanceUserField";
import {useQueryClient} from "@tanstack/react-query";


interface Props {
    objToEdit: IUserField | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IUserField | undefined) => void,
}

const UserFieldForm: React.FC<Props> = ({objToEdit: initialObj, onClose, onSelect}) => {
    const queryClient = useQueryClient();
    const {selectedCategory, selectedEstimate, selectedFinalBalance,} = useApp()
    const [label, setLabel] = useState<string>()
    const [value, setValue] = useState<number>()
    const [amount, setAmount] = useState<number>()
    const [weight, setWeight] = useState<number>()
    const [type, setType] = useState<string>()
    const [typeObj, setTypeObj] = useState<IFieldType>()
    const [description, setDescription] = useState<string>()
    const {pathname} = useLocation()


    const createMutationToEstimate = useApiCreateEstimateUserField(selectedEstimate?.id);
    const createMutationToFinalBalance = useApiCreateFinalBalanceUserField(selectedFinalBalance?.id);

    const estimateUpdateMutation = useApiUpdateEstimateUserField();
    const finalBalanceUpdateMutation = useApiUpdateFinalBalanceUserField();

    useEffect(() => {
        if (initialObj) {
            setLabel(initialObj.label)
            setValue(initialObj.value)
            setAmount(initialObj.amount)
            setWeight(initialObj.weight)
            setType(initialObj.type)
            setDescription(initialObj.description)
        }
    }, [initialObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                label: label,
                value: value,
                amount: amount,
                type: type,
                description: description,
                ...(selectedCategory && {category: selectedCategory.id}),
            };


            let newData;

            if (initialObj !== null && initialObj !== undefined) {
                if (pathname === '/estimate') {
                    newData = await estimateUpdateMutation.mutateAsync({id: initialObj.id, data: payload});
                }
                if (pathname === '/final_balance') {
                    newData = await finalBalanceUpdateMutation.mutateAsync({id: initialObj.id, data: payload});
                }

            } else {
                if (pathname === '/estimate') {
                    newData = await createMutationToEstimate.mutateAsync({data: payload});
                } else if (pathname === '/final_balance') {
                    newData = await createMutationToFinalBalance.mutateAsync({data: payload});
                }
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ['useApiGetUserEstimates', selectedEstimate?.user.id]});


        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false)
        setAllRequirementAreSatisfied(true);
    }, []);

    useEffect(() => {
        setTypeObj(field_types.find((item) => item.text === type));
    }, [type]);


    return (
        <Box>
            <MuiBorderLabel label={'processing'}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setLabel(e.target.value)}
                            label='label processing'
                            type="text"
                            value={label || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FieldTypeSelect obj={typeObj} onChange={(obj) => setType(obj?.text)}
                                         className={''}></FieldTypeSelect>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setValue(parseFloat(e.target.value))}
                            label='value'
                            type="text"
                            value={value || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                            label='amount'
                            type="text"
                            value={amount || ''}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiInputTextField
                            onChange={(e) => setDescription(e.target.value)}
                            label='description'
                            type="text"
                            value={description || ''}
                        />
                    </Grid>
                </Grid>
            </MuiBorderLabel>

            <Box style={{display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '8px'}}>
                <UndoButton onClose={onClose}/>
                {allRequirementAreSatisfied &&
                    <EdiliziaAppButton label={'save'} icon={<FaSave/>} onClick={handleSubmit}/>
                }
            </Box>

        </Box>
    );
};

export default UserFieldForm;
