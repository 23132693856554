import {IEstimate} from "../../utils/interfaces/Estimate/IEstimate";
import {useTheme} from "@mui/material/styles";
import {useQueryClient} from "@tanstack/react-query";
import useApiGetUserEstimates from "./api-hooks/useApiGetUserEstimates";
import {useApp} from "../../utils/global-contexts/AppContext";
import React, {useEffect, useMemo, useState} from "react";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../ui/MuiReactTable/MuiReactTableStyles";
import {Avatar, Box, CircularProgress} from "@mui/material";
import MuiReadOnlyBoxBody from "../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import Images from "../../assets/images";
import {Add, Delete, Edit} from "@mui/icons-material";
import {formatDateTime} from "../../logics/helpers";
import {useTranslation} from "react-i18next";
import EstimateStatusBar from "./EstimateStatusBar";
import MuiModal from "../../ui/MuiModals/MuiModal";
import EstimateStatusChanger from "./EstimateStatusChanger";
import useApiDeleteEstimate from "./api-hooks/useApiDeleteEstimate";
import MuiAlertDialog from "../../ui/MuiAlertDialog";
import EstimateForm from "./EstimateForm";
import useApiCreateEstimate from "./api-hooks/useApiCreateEstimate";
import useApiCreateFinalBalanceFromEstimate from "./api-hooks/useApiCreateFinalBalanceFromEstimate";
import {useResponseMessagesContext} from "../../utils/global-contexts/ResponseMessageContext";


interface Props {
}


const EstimateTable = ({}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const {userId, setSelectedEstimate, selectedEstimate} = useApp();
    const queryClient = useQueryClient();
    const {addMessage} = useResponseMessagesContext()

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [selectedRow, setSelectedRow] = useState<IEstimate | undefined>()
    const [rows, setRows] = useState<IEstimate[] | undefined>()
    const [isStatusModalChangerOpen, setIsStatusModalChangerOpen] = useState(false)


    const [isCreateFinalBalanceConfirmationOpen, setIsCreateFinalBalanceConfirmationOpen] = useState(false)
    const createFinalBalanceMutation = useApiCreateFinalBalanceFromEstimate()
    const handleCreateFinalBalances = async () => {
        const promises = table.getSelectedRowModel().rows.map(estimate => createFinalBalanceMutation.mutateAsync({
            estimate_id: estimate.original.id,
        }));

        try {
            await Promise.all(promises);
        } catch (error) {
        }
        setIsCreateFinalBalanceConfirmationOpen(false);
        addMessage({message: t('create successfully'), severity: 'info'});
        table.resetRowSelection()
        await queryClient.invalidateQueries({queryKey: ['useApiGetUserFinalBalances', userId]});

    };
    const handleCancelCreateFinalBalances = () => {
        setIsCreateFinalBalanceConfirmationOpen(false);
    };

    const [statusIdFilter, setStatusIdFilter] = useState<number | null>(null);
    const handleStatusIdFilterChange = (statusId: number | null) => {
        setStatusIdFilter(statusId);
    };

    const handleStatusModalCloser = () => {
        setIsStatusModalChangerOpen(false);
        table.resetRowSelection()
    };

    const createMutation = useApiCreateEstimate();
    const handleCreateEstimate = async () => {
        try {
            const payload = {
                type: "estimate",
                name: " ",
                user: userId,
            };

            await createMutation.mutateAsync({data: payload});
        } catch (error) {
        }
    }
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteEstimate();
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        const deletePromises = table.getSelectedRowModel().rows.map(async estimate => {
            await apiDelete({pk: estimate.original.id});
        });

        try {
            await Promise.all(deletePromises);
        } catch (error) {
        }


        setDeleteConfirmationOpen(false);
        table.resetRowSelection()
        await queryClient.invalidateQueries({queryKey: ['useApiGetUserEstimates', userId]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading} = useApiGetUserEstimates(userId);

    useEffect(() => {
        table.resetRowSelection()
        if (data) {
            setRows(data);
            if (statusIdFilter !== null) {
                const filteredData = data.filter(item => item.status === statusIdFilter);
                setRows(filteredData);
            } else {
                setRows(data);
            }
        }
    }, [data, statusIdFilter]);


    const CustomCellRenderer: React.FC<{ row: IEstimate, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IEstimate>[]>(
        () => [
            {
                id: "custom_actions",
                header: "status",
                columnDefType: "display",
                Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
            },
            {
                accessorKey: 'number',
                header: t('number'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'date',
                header: t('date'),
                Cell: ({row}) => {
                    return formatDateTime(row.original.date);
                }
            },
            {
                header: t('client'),
                filterFn: 'contains',
                Cell: ({row}) => {
                    const {client} = row.original;

                    if (!client) return '';

                    return client.type === 'company' ? client.company_name : client.name;
                }
            },
            {
                accessorKey: 'totalEstimate',
                header: t('total estimate'),
            },
        ],
        []
    );


    const table = useMaterialReactTable<IEstimate>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableGlobalFilter: false,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'black',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <img src={Images.estimatesYellow} alt={'preventivi'}
                             style={{width: '24px', height: '24px'}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'estimates'}/>
                    <MRTMuiButton
                        icon={<Add style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            handleCreateEstimate();
                        }} label={t('add estimate')}/>
                    {table.getSelectedRowModel().rows.length === 0 &&
                        <EstimateStatusBar onStatusChange={handleStatusIdFilterChange}/>}
                </>
                {table.getSelectedRowModel().rows.length > 0 &&
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <MRTMuiButton
                            icon={<img src={Images.cambio_stato} alt={t('change status')}
                                       style={{width: '24px', height: '24px'}}/>}
                            onClick={() => {
                                setIsStatusModalChangerOpen(true);
                            }} label={t('change estimates status')}/>
                        <MRTMuiButton
                            icon={<Delete style={{color: theme.palette.warning.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setDeleteConfirmationOpen(true);
                            }} label={t('delete estimates')}/>

                        <MRTMuiButton
                            icon={<img src={Images.consuntiviBlack} alt={t('create final balances')}
                                       style={{width: '24px', height: '24px'}}/>}
                            onClick={() => {
                                setIsCreateFinalBalanceConfirmationOpen(true);
                            }} label={t('create final balances')}/>
                    </Box>
                }
            </Box>
        ),
        enableRowSelection: true,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, staticRowIndex, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedEstimate(row.original);
                    setSelectedRow(row.original);
                    closeMenu()
                }}
                table={table}
            />,
        ],
    });


    return <>
        {isLoading ? <CircularProgress/> :
            <>
                {selectedEstimate ?
                    <>
                        <EstimateForm
                            onClose={() => {
                                setSelectedEstimate(undefined);
                            }
                            }
                        />
                    </>
                    :
                    <>
                        <MaterialReactTable table={table}/>

                        <MuiModal
                            top="50%"
                            left="50%"
                            width={'50vH'}
                            open={isStatusModalChangerOpen}
                            onClose={handleStatusModalCloser}
                        >
                            <EstimateStatusChanger
                                estimates={table.getSelectedRowModel().rows}
                                onClose={handleStatusModalCloser}
                            />
                        </MuiModal>
                        <MuiAlertDialog
                            open={isDeleteConfirmationOpen}
                            title="Delete Confirmation"
                            content="Are you sure you want to delete these estimates?"
                            onClose={handleCancelDelete}
                            onConfirm={handleConfirmDelete}
                        />

                        <MuiAlertDialog
                            open={isCreateFinalBalanceConfirmationOpen}
                            title="Create Final Balances Confirmation"
                            content="Are you sure you want to create final balances?"
                            onClose={handleCancelCreateFinalBalances}
                            onConfirm={handleCreateFinalBalances}
                        />
                    </>}

            </>}
    </>;

};

export default EstimateTable;
