import {useTheme} from "@mui/material/styles";
import React, {useEffect, useMemo, useState} from "react";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {useTranslation} from "react-i18next";
import {useApp} from "../../utils/global-contexts/AppContext";
import {IUserField} from "../../utils/interfaces/Estimate/IUserField";
import {IEstimate} from "../../utils/interfaces/Estimate/IEstimate";
import useApiDeleteEstimateUserfield from "./api-hooks/useApiDeleteEstimateUserfield";
import useApiGetEstimateUserfields from "./api-hooks/useApiGetEstimateUserfields";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../ui/MuiReactTable/MuiReactTableStyles";
import {Avatar, Box, CircularProgress, Tooltip} from "@mui/material";
import Images from "../../assets/images";
import MuiReadOnlyBoxBody from "../../ui/MuiDisplayData/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../ui/MuiReactTable/MRTMuiButton";
import {Add, Delete, Edit} from "@mui/icons-material";
import MuiAlertDialog from "../../ui/MuiAlertDialog";
import useCustomCellRenderer from "./useCustomCellRenderer";
import MuiModal from "../../ui/MuiModals/MuiModal";
import UserFieldForm from "./UserFieldForm";
import {useLocation} from "react-router-dom";
import useApiCreateProcessing from "../Catalogue/Processing/api-hooks/useApiCreateProcessing";
import CategoryTable from "../Catalogue/Category/CategoryTable";
import ProcessingTable from "../Catalogue/Processing/ProcessingTable";
import useApiGetFinalBalance from "../FinalBalance/api-hooks/useApiGetFinalBalance";
import useApiDeleteFinalBalanceUserfield from "./api-hooks/useApiDeleteFinalBalanceUserfield";
import useApiReorderEstimateUserField from "./api-hooks/useApiReorderEstimateUserField";
import useApiReorderFinalBalanceUserField from "./api-hooks/useApiReorderFinalBalanceUserField";


interface Props {

}


const UserFieldTable = ({}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const {setSelectedCategory, selectedEstimate, selectedFinalBalance} = useApp();
    const {pathname} = useLocation()

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [selectedRow, setSelectedRow] = useState<IUserField | undefined>()
    const [rows, setRows] = useState<IUserField[] | undefined>()
    const [estimate, setEstimate] = useState<IEstimate>()

    const [isStatusModalChangerOpen, setIsStatusModalChangerOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isUncategorizedTableOpen, setIsUncategorizedTableOpen] = useState<boolean>(false)
    const [isCategoryTableOpen, setIsCategoryTableOpen] = useState<boolean>(false)


    const estimateMoveMutation = useApiReorderEstimateUserField()
    const finalBalanceMoveMutation = useApiReorderFinalBalanceUserField();


    const createProcessingMutation = useApiCreateProcessing()
    const handleSubmitCreateMutation = async (row: IUserField) => {
        try {
            const payload = {
                label: row.label,
                value: row.value,
                type: row.type,
                description: row.description,
            };

            await createProcessingMutation.mutateAsync({data: payload});
            pathname === '/estimate' ? await refetchEstimateUserFields() : await refetchFinalBalanceUserFields()

        } catch (error) {
        }
    }


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const estimateUserFieldDeleteMutation = useApiDeleteEstimateUserfield();
    const finalBalanceUserFieldDeleteMutation = useApiDeleteFinalBalanceUserfield();
    const {mutateAsync: apiDelete} = pathname === '/estimate' ? estimateUserFieldDeleteMutation : finalBalanceUserFieldDeleteMutation;
    const handleConfirmDelete = async () => {
        try {
            await apiDelete({pk: selectedRow?.id});
            pathname === '/estimate' ? await refetchEstimateUserFields() : await refetchFinalBalanceUserFields()
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {
        data: estimateUserFieldsData,
        refetch: refetchEstimateUserFields,
        isLoading: isLoadingEstimateUserFieldsData
    } = useApiGetEstimateUserfields(selectedEstimate?.id);
    const {
        data: finalBalanceUserFieldsData,
        refetch: refetchFinalBalanceUserFields,
        isLoading: isLoadingFinalBalanceUserFieldsData
    } = useApiGetFinalBalance(selectedFinalBalance?.id);

    useEffect(() => {
        if (pathname === '/estimate') {
            setRows(estimateUserFieldsData)
        }
        if (pathname === '/final_balance') {
            setRows(finalBalanceUserFieldsData?.usersfields)
        }
    }, [estimateUserFieldsData, finalBalanceUserFieldsData, pathname]);


    const CustomCellRenderer: React.FC<{ row: IUserField, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IUserField>[]>(
        () => [
            {
                id: "custom_actions",
                header: t(""),
                columnDefType: "display",
                Cell: ({row}) => (
                    <Box sx={{display: 'flex'}}>
                        {!row.original.in_catalogue &&
                            <Tooltip title={t("insert in Catalogue")}>
                                <Avatar sx={{
                                    backgroundColor: 'black',
                                    border: `2px solid ${(theme as any).palette.primary.main}`,
                                    marginRight: 1,
                                }}>
                                    <img src={Images.navbar_listino_yellow} alt={''}
                                         style={{width: '24px', height: '24px', cursor: 'pointer'}}
                                         onClick={
                                             () => {
                                                 handleSubmitCreateMutation(row.original);
                                             }
                                         }
                                    />
                                </Avatar>
                            </Tooltip>
                        }
                    </Box>
                )
            },
            {
                accessorKey: 'label',
                header: t('label'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'weight',
                header: t('weight'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'type',
                header: t('field type'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'amount',
                header: t('amount'),
            },
            {
                accessorKey: 'value',
                header: t('value'),
            },
            {
                accessorKey: 'description',
                header: t('description'),
                filterFn: 'contains',
            },
        ],
        []
    );


    const table = useMaterialReactTable<IUserField>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows ? rows?.length > 25 : false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },

        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'black',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <img src={Images.navbar_lavorazioni_yellow} alt={'processings'}
                             style={{width: '24px', height: '24px'}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'processings'}/>
                    <MRTMuiButton
                        icon={<Add style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true);
                        }} label={t('add processing')}/>
                    <MRTMuiButton
                        icon={<img src={Images.navbar_listino_yellow} alt={''}
                                   style={{width: '24px', height: '24px', cursor: 'pointer'}}
                        />}
                        onClick={() => {
                            setIsCategoryTableOpen(true);
                        }} label={t('add processing from categorized catatalogue')}/>
                    <MRTMuiButton
                        icon={<img src={Images.navbar_lavorazioni_yellow} alt={''}
                                   style={{width: '24px', height: '24px', cursor: 'pointer'}}
                        />}
                        onClick={() => {
                            setSelectedCategory(undefined);
                            setIsUncategorizedTableOpen(true);
                        }} label={t('add processing from uncategorized processings')}/>
                </>
            </Box>
        ),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, staticRowIndex, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        enableRowOrdering: true,
        enableSorting: false,
        muiRowDragHandleProps: ({table}) => ({
            onDragEnd: async () => {
                const {draggingRow, hoveredRow} = table.getState();
                if (hoveredRow && draggingRow && rows) {
                    rows.splice(
                        (hoveredRow as MRT_Row<IUserField>).index,
                        0,
                        rows.splice(draggingRow.index, 1)[0],
                    );
                    setRows([...rows]);

                    const data = {
                        end_weight: hoveredRow.index ? hoveredRow.index + 1 : 0
                    }
                    let moveMutation = pathname === '/estimate' ? estimateMoveMutation : finalBalanceMoveMutation;
                    await moveMutation.mutateAsync({
                        id: draggingRow.original.id,
                        data: data,
                    });
                }
            },
        }),

    });


    return <>
        {(isLoadingEstimateUserFieldsData || isLoadingFinalBalanceUserFieldsData) ? <CircularProgress/> :
            <>
                <MaterialReactTable table={table}/>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'100vH'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >
                    <UserFieldForm
                        objToEdit={selectedRow}
                        onClose={() => {
                            setIsEditFormOpen(false)
                            pathname === '/estimate' ? refetchEstimateUserFields() : refetchFinalBalanceUserFields()
                        }}
                    />
                </MuiModal>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete this user field?"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="25%"
                    left="50%"
                    width={'130vH'}
                    open={isCategoryTableOpen}
                    onClose={() => setIsCategoryTableOpen(false)}
                    disableBackdropClick={true}
                >
                    <CategoryTable
                        onClose={() => setIsCategoryTableOpen(false)}
                    />
                </MuiModal>

                <MuiModal
                    top="25%"
                    left="50%"
                    width={'130vH'}
                    open={isUncategorizedTableOpen}
                    onClose={() => setIsUncategorizedTableOpen(false)}
                    disableBackdropClick={true}
                >
                    <ProcessingTable
                        onClose={() => setIsUncategorizedTableOpen(false)}
                    />
                </MuiModal>


            </>}
    </>;

};

export default UserFieldTable;
