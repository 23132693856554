import {useQuery, UseQueryResult} from "@tanstack/react-query";
import useApi, {CustomAxiosError} from "./api-hooks/useApi";
import {AxiosError} from "axios";

interface IWhoAmIResponse {
    user: number,
    token: string,
    deadline: string,
    role: 'ROLE_USER' | 'ROLE_WEBSITE',
    version: {
        valid: boolean,
        user_version: string,
        latest_version: string,
        latest_version_description: string,
        upgrade: string,
        message: string
    },
    has_active_subscription: boolean,
    has_requested_account_deletion: boolean,
}


const useGetWhoAmI = (): UseQueryResult<IWhoAmIResponse, CustomAxiosError> => {
    const {get} = useApi()

    async function whoAmI(): Promise<IWhoAmIResponse> {
        try {
            const response = await get(`/users/whoami/?version=1.0.7`);
            return response.data;
        } catch (error) {
            throw error as AxiosError ;
        }
    }

    return useQuery<IWhoAmIResponse, CustomAxiosError>({
        queryKey: ["whoAmI"],
        queryFn: whoAmI
    });
};

export default useGetWhoAmI;
